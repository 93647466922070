<template>
    <v-container class="container-main">

        <!-- <v-snackbar
            v-show="this.$route.params.id =='306' && this.loadMore"   
            :value="true" 
            :timeout="-1"
            absolute
            shaped
            left
            top
            color="red accent-4"
            elevation="24"
            class="ml-10"
        >Data gathering is not yet finished  please wait, as it will take a few minutes.
        </v-snackbar> -->
        <tool-bar v-show="checkActive !='allData' && checkActive"  :toolbar="toolbar" @backToMasterCategory="backToMasterCategory" @editCategory="editCategory"></tool-bar>
        <tool-bar1 v-show="checkActive =='allData' || !checkActive" :toolbar="toolbar1" @backToMasterCategory="backToMasterCategory"></tool-bar1>

        <v-data-table
        v-if="this.$route.params.id =='306'"
        class="data-table-header"
        :headers="categoryHeaders306"
        :items="allCategoryData306"
        fixed-header
        :height="tableHeight"
        :search="searchQuery"
        :items-per-page="50"
        id='data-table'
        >

                <template v-slot:body="{ items }">
                    <tr v-for="item in items" :key="item.id">
                        <td v-show="editMode" style="width: 10px; padding-left: 10px;">
                            <v-checkbox dense color="indigo" v-model="item.checked" @click="setSelectedRow({BUZAI_CD: item.Id || item.buzai_code, BUZAI_MEI: item.Name || item.buzai_mei, TANI_MEI:item.UnitName || item.tani_mei  ,TEKIYO: item.ItemName || item.tekiyo,isChecked: item.checked})"></v-checkbox>
                        </td>
                        <td class="text-center">
                            {{item.Id  || item.buzai_code }}
                        </td>
                        <td class="text-center">
                            {{item.Name || item.buzai_mei }}
                        </td>
                        <td class="text-center">
                            {{item.ItemName || item.tekiyo }}
                        </td>
                    </tr>
                </template>
            </v-data-table>


            <v-data-table
            v-else
                class="data-table-header"
                :headers="categoryHeaders"
                :items="displayCategoryData"
                fixed-header
                :height="tableHeight"
                :search="search"
                :items-per-page="50"    
                :footer-props="footerProps"
                :options="options"
                @pagination="updateCurrentPage"
                id='data-table'
            >     

                <template v-slot:body="{ items }">
                    <tr v-for="item in items" :key="item.id">
                        <td v-show="editMode" style="width: 10px; padding-left: 10px;">
                            <v-checkbox dense color="indigo" v-model="item.checked" @click="setSelectedRow({DISP_ORDER: parseInt(item.DISP_ORDER || item.disp_order),HATCHU_GYOSYU_CD: item.HATCHU_GYOSYU_CD || $route.params.id, BUZAI_CD: item.BUZAI_CD || item.buzai_code, BUZAI_MEI: item.BUZAI_MEI || item.buzai_mei, TANI_CD: item.TANI_CD || item.tani_cd, TANI_MEI: item.TANI_MEI || item.tani_mei, TEKIYO: item.TEKIYO || item.tekiyo,isChecked: item.checked})"></v-checkbox>
                        </td>
                        <td class="text-center">
                            {{ !item.DISP_ORDER ? '' : item.DISP_ORDER }}
                        </td>
                        <td class="text-center">
                            {{item.BUZAI_CD || item.buzai_code }}
                        </td>
                        <td class="text-center">
                            {{item.BUZAI_MEI || item.buzai_mei }}
                        </td>
                        <td class="text-center">
                            {{item.TANI_CD || item.tani_cd }}
                        </td>
                        <td class="text-center">
                            {{ item.additional_industry_id == '208' || item.HATCHU_GYOSYU_CD == '208' ?
                                item.Hinban || item.tekiyo : item.TEKIYO || item.tekiyo }}
                        </td>
                    </tr>
                </template>
            </v-data-table>

            <v-footer dense inset color="white" app class="mb-5" v-if="this.$route.params.id =='306'">
                <v-layout  column justify-center fill-height  class="show-records">
                    <v-row class="ml-2">
                        <h3 class="mt-1 mr-2"><b>Legends: </b></h3>
                            <!-- <v-chip @click="selectedData('allData')" label :color="getActiveLegend('active')" dark small class="mr-3">
                                <span>All Data: {{ activeCategoryData.length }}</span> 
                            </v-chip> -->
                            <v-chip label :color="getActiveLegend('all')" dark small class="mr-3">
                                <span>Active: {{ allCategoryData306Original.length }}</span> 
                            </v-chip>
                            <!-- <v-chip @click="selectedData('inActiveData')" label :color="getActiveLegend('inactive')" dark small class="mr-3">
                                <span>Inactive:</span> 
                            </v-chip> -->
                    </v-row>
                </v-layout>
            </v-footer>


            <v-footer dense inset color="white" app class="mb-5" v-else>
                <v-layout  column justify-center fill-height  class="show-records">
                    <v-row class="ml-2">
                        <h3 class="mt-1 mr-2"><b>Legends: </b></h3>
                            <v-chip @click="selectedData('allData')" label :color="getActiveLegend('all')" dark small class="mr-3">
                                <span>All Data: {{ allCategoryData.length }}</span> 
                            </v-chip>
                            <v-chip @click="selectedData('activeData')" label :color="getActiveLegend('active')" dark small class="mr-3">
                                <span>Active: {{ activeCategoryData.length }}</span> 
                            </v-chip>
                            <v-chip @click="selectedData('inActiveData')" label :color="getActiveLegend('inactive')" dark small class="mr-3">
                                <span>Inactive: {{ activeCategoryData.length ? inactiveCategoryData.length : allCategoryData.length }}</span> 
                            </v-chip>
                    </v-row>
                </v-layout>
            </v-footer>

        <float-action 
            :floatbtn="floatbtn"
            @toggleActiveData="toggleActiveData"
            @toggleInactiveData="toggleInactiveData"
        >
        </float-action>

        <v-overlay :value="loadMore" overlay>
            <v-progress-circular indeterminate :size="64"></v-progress-circular>
        </v-overlay>

    </v-container>
</template>
<script>
import axios from 'axios';
import swal from 'sweetalert2'
import { mapActions, mapState } from 'vuex';
import FloatAction from '../includes/FloatAction.vue'
import Toolbar from '../includes/Toolbar.vue'
import Toolbar1 from '../includes/Toolbar.vue'


    export default {
        components: {
            "float-action": FloatAction,
            "tool-bar": Toolbar,
            "tool-bar1": Toolbar1
        },
        sockets: {
            updateGcTsuikaData: function(socket) {
                if(socket.system == 'gc-tsuika' && socket.dispatchType == 'response-local') {
                    if(socket.request == 'getSelectedCategory') {
                        if(this.$route.params.id =='306'){
                        //     // this.allCategoryData306 = this.allCategoryData306.concat(socket.data);
                        //     // console.log(this.allCategoryData306);
                            this.getActiveCategoryData();
                        }else{
                            if(socket.data.length) {
                                if(this.loggedInUser.user_id == socket.user_id) {
                                    this.allCategoryData = socket.data;
                                    // console.log(this.allCategoryData,'allCategoryData');
                                    this.getActiveCategoryData();
                                }
                            }
                            if(socket.data.length == 0) {
                            this.loadMore = false;
                            }
                        }

                    }
                }

            }
        },
        data: () => ({
            allCategoryData306Original: [],
            searchQuery: '',
            floatbtn: {
                edit: false,
                active: false,
                inactive: false,
            },
            toolbar: {
                back: true,
                edit: true,
                hasSearch: true,
                disabled: false,
            },
            toolbar1: {
                back: true,
                hasSearch: true,
                disabled: false,
            },

            selectAll: false,
            categoryData: [],
            loadMore: false,
            tableHeight: window.innerHeight - 220,
            antSpin: false,
            cntActiveData: [],
            cntInactiveData: [],
            activeCategoryData: [],
            inactiveCategoryData: [],
            selectedActive: false,
            disabledItems: false,
            dataToBeDisplayed: [],
            isAllData: false,
            checkActive: '',
            allCategoryData: [],
            allCategoryData306: [],
            headers: [
                    {
                        text: '表示側',
                        align: 'center',
                        value: 'DISP_ORDER',
                        class: 'white--text title'
                    },
                    {
                        text: 'コード',
                        align: 'center',
                        value: 'BUZAI_CD',
                        class: 'white--text title'
                    },
                    {
                        text: '部材名',
                        align: 'center',
                        value: 'BUZAI_MEI',
                        class: 'white--text title'
                    },
                    {
                        text: '単位',
                        align: 'center',
                        value: 'TANI_CD',
                        class: 'white--text title'
                    },
                    {
                        text: '取込区分 ',
                        align: 'center',
                        value: 'TEKIYO',
                        class: 'white--text title'
                    },
                    
                ],
            currentPage: 1,
            defaultPage: 50,
        }),
        computed: {
            ...mapState(['accessRightData', 'search', 'loggedInUser']),
            options() {
                return {
                    page: this.currentPage,
                    itemsPerPage: this.defaultPage,
                };
            },
            displayCategoryData() {
                let filteredData = [];

                if(!this.checkActive || this.checkActive == 'allData') {
                    filteredData = this.sortByProperty(this.allCategoryData);
                } else if(this.checkActive == 'activeData') {
                    let toUpperCase = this.activeCategoryData.map(rec => {
                        return Object.keys(rec).reduce((acc, key) => {
                            if(key == 'buzai_code') {
                                acc['BUZAI_CD'] = rec['buzai_code']
                            } else {
                                acc[key.toUpperCase()] = rec[key];
                            }
                            return acc;
                        }, {})
                    });
                    filteredData = this.sortByProperty(toUpperCase);
                } else if(this.checkActive == 'inActiveData') {
                    filteredData = this.sortByProperty(this.inactiveCategoryData);
                }
                return filteredData;
            },
            displayCategoryData306() {
                let filteredData = [];

                if(!this.checkActive || this.checkActive == 'activeData') {
                    filteredData = this.activeCategoryData;
                } else if(this.checkActive == 'activeData') {
                    // console.log(this.activeCategoryData,'this.activeCategoryData');
                    filteredData = this.activeCategoryData;
                } else if(this.checkActive == 'inActiveData') {
                    filteredData = this.inactiveCategoryData;
                }
                return filteredData;
            },
            footerProps() {
                let data;
                let allData = [-1];

                if(!this.checkActive || this.checkActive == 'allData') {
                    data = this.allCategoryData;
                } else if (this.checkActive == 'activeData') {
                    data = this.activeCategoryData;
                } else {
                    data = this.inactiveCategoryData;
                }
                
                const len = data.length / 50;
                let arr = [];
                var foorterNum = 0;
                if(len % 0 != 0) {
                    foorterNum = Math.trunc(len) + 1;
                }
                for(let i = 1; i <= foorterNum; i++) {
                    arr.push(i * 50)
                }
                if(arr.length == 1) {
                    arr.push(100)
                } 

                return {'items-per-page-options': arr.concat(allData)};
            },
            disabledSelectAll() {
                let result;

                if(this.hasActive(this.displayCategoryData)) result = true;
                if(!this.hasActive(this.displayCategoryData)) result = false;

                return result;
            },
            categoryHeaders() {
                let headers = [
                    {
                        text: '表示側',
                        align: 'center',
                        value: 'DISP_ORDER',
                        class: 'white--text title'
                    },
                    {
                        text: 'コード',
                        align: 'center',
                        value: 'BUZAI_CD',
                        class: 'white--text title'
                    },
                    {
                        text: '部材名',
                        align: 'center',
                        value: 'BUZAI_MEI',
                        class: 'white--text title'
                    },
                    {
                        text: '単位',
                        align: 'center',
                        value: 'TANI_CD',
                        class: 'white--text title'
                    },
                    {
                        text: '取込区分 ',
                        align: 'center',
                        value: 'TEKIYO',
                        class: 'white--text title'
                    },
                    
                ]

                if(!this.editMode) {
                    return headers;
                }

                if(this.editMode) {
                    headers.unshift({
                        text: '',
                        align: 'center',
                        value: '',
                        class: 'white--text title'
                    })
                    return headers;
                }

                return headers;

            },
            categoryHeaders306() {
                let headers = [
                    {
                        text: 'コード',
                        align: 'center',
                        // value: 'BUZAI_CD',
                        value: 'Id',
                        class: 'white--text title',
                        name: 'BUZAI_CD'
                    },
                    {
                        text: '部材名',
                        align: 'center',
                        // value: 'BUZAI_MEI',
                        value: 'Name',
                        class: 'white--text title',
                        name: 'BUZAI_MEI'
                    },
                    {
                        text: '取込区分 ',
                        align: 'center',
                        // value: 'TEKIYO',
                        value: 'ItemName',
                        class: 'white--text title',
                        name: 'TEKIYO'
                    },
                    
                ]

                if(!this.editMode) {
                    return headers;
                }

                if(this.editMode) {
                    headers.unshift({
                        text: '',
                        align: 'center',
                        value: '',
                        class: 'white--text title'
                    })
                    return headers;
                }

                return headers;

            },
        },
        methods: {
            ...mapActions(['getAccessRights']),
            getActiveLegend(val){
                let text = '';
                if(!this.checkActive || this.checkActive == 'allData') {
                    if(val == 'all') {
                        text = 'light-green darken-4'
                    } else {
                        text = 'blue-grey darken-3';
                    }
                } else if(this.checkActive == 'activeData') {
                    if(val == 'active') {
                        text = 'light-green darken-4'
                    } else {
                        text = 'blue-grey darken-3';
                    }
                } else if(this.checkActive == 'inActiveData') {
                    if(val == 'inactive') {
                        text = 'light-green darken-4'
                    } else {
                        text = 'blue-grey darken-3';
                    }
                }
                return text;
            },
            deleteCheckedProp(array) {
                return array.map(r => delete r?.checked);
            },
            sortByProperty(array) {
                // const regex = /[A-Za-z]/;
                return array.sort(function (a, b) {
                    return a.BUZAI_CD.localeCompare(b.BUZAI_CD, undefined, {
                        numeric: true,
                    });
                });
            },
            updateCurrentPage(pagination) {
                this.currentPage = pagination.page;
                this.defaultPage = pagination.itemsPerPage
            },
            selectedData(value) {
                if(value == 'allData') {
                    this.dataToBeDisplayed = this.allCategoryData
                    this.checkActive = 'allData';
                }
                if(value == 'activeData') {
                    this.dataToBeDisplayed = this.activeCategoryData;
                    this.checkActive = 'activeData';

                }
                if(value == 'inActiveData') {
                    this.dataToBeDisplayed = this.inactiveCategoryData
                    this.checkActive = 'inActiveData';
                }
            },

            activeLatestItem(item){
                axios({
                    method: 'post',
                    url: `${window.api}/mysql/activeLatestItem`,
                    data: {
                        categoryId: this.$route.params.id,
                        data: item 
                    }
                }).then(() => {
                    this.getActiveCategoryData();
                })
            },
            isAllZero(string) {
                return /^0*$/.test(string);
            },
            hasLetters(string) {
                return /[a-zA-Z]/.test(string);
            },

            async getActiveCategoryData() {
                await axios({
                    method: 'post',
                    url: `${window.api}/mysql/getActiveCategories`,
                    data: {
                        categoryId: this.$route.params.id
                    },
                    headers: {
                        'x-api-key' : process.env.VUE_APP_HEADERS
                    }
                }).then(res => {
                    if (res.data.length && res.status == 200){
                        if(this.$route.params.id =='306'){
                            this.allCategoryData306Original = res.data;
                            this.allCategoryData306 = res.data;
                            this.loadMore =false
                        }else{
                            const active = res.data
                            this.activeCategoryData = active.filter(item => item.buzai_code !== null && item.buzai_mei !== null && item.tani_cd !== null)
                            this.inactiveCategoryData = this.allCategoryData.filter(rec => !res.data.map(r => r.buzai_code).includes(rec.BUZAI_CD)).sort((a, b) => a.BUZAI_CD - b.BUZAI_CD)
                        }
                    }else{
                        this.activeCategoryData = []
                        if(this.$route.params.id =='306'){
                            this.inactiveCategoryData = this.allCategoryData306
                        }else{
                            this.inactiveCategoryData =this.allCategoryData
                        }
                    }
                })
            },
            hasActive(array){
                if(array.length) {
                    return array.find(active => active.isActive)
                }
            },
            toggleSelectAll(data){
                this.$store.state.selectedRows.splice(0);

                if(this.selectAll) {
                    for(let i = 0; i < data.length; i++) {
                        this.selectedRows.push({
                            HATCHU_GYOSYU_CD: data[i].HATCHU_GYOSYU_CD,
                            BUZAI_CD: data[i].BUZAI_CD,
                            BUZAI_MEI: data[i].BUZAI_MEI
                        })
                        // data[i].checked = true;
                    }
                }
            },
            async toggleActiveData(){
                // console.log(this.selectedRows,'this.selectedRows');
                // console.log(this.$route.params.id);
                if (this.$route.params.id =='306'){
                    await swal.fire({
                        text: "Are you sure you want to active all selected item?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#224E94",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Activate",
                    }).then((result) => {
                        const categoryId = this.$route.params.id
                        if (result.isConfirmed) {
                            axios({
                                method: 'POST',
                                url: `${window.api}/mysql/insertIndustryCategories`,
                                data: {
                                    categoryId: categoryId,
                                    categoryData: this.selectedRows,
                                },
                                headers: {
                                    'x-api-key' : process.env.VUE_APP_HEADERS
                                }
                            }).then(res => {
                                if(res.data) {
                                    swal.fire({
                                        position: "center",
                                        icon: "success",
                                        title: "Successfully activated",
                                        showConfirmButton: false,
                                        timer: 1500,
                                    });
                                    this.$store.state.editMode = false;
                                    this.getActiveCategoryData();
                                    this.$store.state.selectedRows.splice(0);
                                }
                            })
                            
                        }
                    });
                }else{

                    const hasEmptyValue = this.selectedRows.some(row => {
                    // return !row.TEKIYO.trim() || !row.BUZAI_MEI.trim() || !row.BUZAI_CD.trim() ||!row.TANI_CD.trim();//*include the last column
                    return !row.BUZAI_MEI.trim() || !row.BUZAI_CD.trim() ||!row.TANI_CD.trim();
                });
                // console.log(hasEmptyValue,'hasEmptyValue');

                if (hasEmptyValue) {
                    swal.fire({
                        width:350,
                        position: "center",
                        icon: "error",
                        title: `Cannot be activate`,
                        text: "Due to incomplete data",
                        showConfirmButton: true,
                        allowOutsideClick: false,
                    }) 
                } else {
                    await swal.fire({
                        text: "Are you sure you want to active all selected item?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#224E94",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Activate",
                    }).then((result) => {
                        const categoryId = this.$route.params.id
                        if (result.isConfirmed) {
                            axios({
                                method: 'POST',
                                url: `${window.api}/mysql/insertIndustryCategories`,
                                data: {
                                    categoryId: categoryId,
                                    categoryData: this.selectedRows,
                                },
                                headers: {
                                    'x-api-key' : process.env.VUE_APP_HEADERS
                                }
                            }).then(res => {
                                if(res.data) {
                                    swal.fire({
                                        position: "center",
                                        icon: "success",
                                        title: "Successfully activated",
                                        showConfirmButton: false,
                                        timer: 1500,
                                    });
                                    this.$store.state.editMode = false;
                                    this.getActiveCategoryData();
                                    this.$store.state.selectedRows.splice(0);
                                }
                            })
                            
                        }
                    });
                }

                }
        
            },
            async toggleInactiveData(){
                // console.log(this.selectedRows,'this.selectedRows');
                await swal
                    .fire({
                    text: "Are you sure you want to deactivate all selected item?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#6B1A1A",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Deactivate",
                    })
                    .then((result) => {
                    if (result.isConfirmed) {
                        if (this.$route.params.id =='306'){
                            
                            axios({
                                method: 'POST',
                                url: `${window.api}/mysql/deleteIndustryCategories306`,
                                data: this.selectedRows,
                                headers: {
                                    'x-api-key' : process.env.VUE_APP_HEADERS
                                }
                            }).then(res => {
                                if(res.data) {
                                    swal.fire({
                                        position: "center",
                                        icon: "success",
                                        title: "Successfully deactivated",
                                        showConfirmButton: false,
                                        timer: 1500,
                                    });
                                    this.$store.state.editMode = false;
                                    this.getActiveCategoryData();
                                    this.$store.state.selectedRows.splice(0);
                                    // this.selectedData('inActiveData');
                            }
                            })
                        }else{
                            axios({
                                method: 'POST',
                                url: `${window.api}/mysql/deleteIndustryCategories`,
                                data: this.selectedRows,
                                headers: {
                                    'x-api-key' : process.env.VUE_APP_HEADERS
                                }
                            }).then(res => {
                                if(res.data) {
                                    swal.fire({
                                        position: "center",
                                        icon: "success",
                                        title: "Successfully deactivated",
                                        showConfirmButton: false,
                                        timer: 1500,
                                    });
                                    this.$store.state.editMode = false;
                                    this.getActiveCategoryData();
                                    this.$store.state.selectedRows.splice(0);
                                    // this.selectedData('inActiveData');
                            }
                            })

                        }
                   
                        
                    }
                });
            },
            backToMasterCategory(){
                this.$router.push(`/industry_master`)
            },
            editCategory() {
                if(!this.checkActive || this.checkActive == 'allData') {
                    this.$store.state.editMode = false;
                } else {
                    this.$store.state.editMode = true;
                }
            },
            async getSelectedCategory() {
                this.$socket.emit('gcTsuikaData', {
                    system: 'gc-tsuika',
                    request: 'getSelectedCategory',
                    requestId: this.$route.params.id,
                    dispatchType: 'request-local',
                    port: this.windowLoc.port,
                    protocol: this.windowLoc.protocol,
                    hostname: this.windowLoc.hostname,
                    user_id: this.loggedInUser.user_id,
                });
            },
            async getActiveCategory() {
                await axios({
                    method: 'post',
                    url: `${window.api}/mysql/getActiveCategory`,
                    data: {
                        id: this.$route.params.id
                    },
                    headers: {
                        'x-api-key' : process.env.VUE_APP_HEADERS
                    }
                }).then(res => {
                    this.$socket.emit('gcTsuikaData', {
                        system: 'gc-tsuika',
                        request: 'getActiveCategory',
                        requestId: this.$route.params.id,
                        requestData: res.data,
                        dispatchType: 'request-local',
                        port: this.windowLoc.port,
                        protocol: this.windowLoc.protocol,
                        hostname: this.windowLoc.hostname,
                    });
                })
            },
        },
        watch: {
            editMode(val){
                if(!val) {

                    this.$store.state.selectedRows.splice(0);

                    this.selectAll = false;

                    this.dataToBeDisplayed.forEach(rec => {
                        rec.checked = false;
                    })
                } else {
                    this.displayCategoryData.forEach(rec => {
                        delete rec.checked
                    })
                }
            },
            checkActive(val) {
                this.$store.state.editMode = false;
                if(val) {
                    if(this.currentPage > 1) {
                        this.currentPage = 1;
                    }

                    if(this.defaultPage > 50 || this.defaultPage == -1) {
                        this.defaultPage = 50;
                    }

                    this.selectedRows.splice(0);

                }
            },
            selectedRows(val) {
                if(val.length) {
                    if(this.checkActive == 'activeData') {
                        this.floatbtn.inactive = true;
                        this.$store.state.editMode = true;
                        this.$store.state.activeMode = false;
                    }

                    if(this.checkActive == 'inActiveData') {
                        this.floatbtn.active = true;
                        this.$store.state.editMode = true;
                        this.$store.state.activeMode = true;
                    }
                }
            },
            allCategoryData(val) {
                if(val.length) {
                    this.loadMore = false;
                }

                if(!val.length) {
                    this.loadMore = false;
                }
            },

            // For Category 306
            search(newVal) {
                if (this.$route.params.id === '306') {
                if (!newVal) {
                    this.allCategoryData306 = this.allCategoryData306Original;
                } else {
                    this.allCategoryData306 = this.allCategoryData306Original.filter(item => {
                    return Object.values(item).join('').toLowerCase().includes(newVal.toLowerCase());
                    });
                }
                }
            }
        },
        mounted() {},
        created() {
            this.$store.state.search = '';
            this.getSelectedCategory().then(() => {
                    this.loadMore = true;
            })
        }
    }
</script>
<style scoped>
.show-records {
    font-size: 10px;
}
.data-table-header ::v-deep th  {
    font-size: 13px !important;
    background-color: #274761 !important;
    font-family: 'Arial' !important;
    text-align: center !important;
    border: 1px solid white !important;
}
</style>